import { useTheme } from '@material-ui/core';
import React, { useRef, useImperativeHandle } from 'react';

export const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const theme = useTheme();
  const textColor = theme.palette.text.primary;
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  return (
    <Component
      onReady={element => (elementRef.current = element)}
      {...props}
      options={{
        showIcon: true,
        iconStyle: 'solid',
        style: { base: { color: textColor } },
      }}
    />
  );
};
