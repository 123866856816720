import {
  Box,
  CircularProgress,
  createStyles,
  Divider,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  ListItemIcon,
  makeStyles,
  Switch,
  Theme,
  Tooltip,
  Typography,
  TypographyProps
} from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {
  mdiAccountCog,
  mdiAccountGroup,
  mdiCloud,
  mdiCodeBracesBox,
  mdiCollage,
  mdiFrequentlyAskedQuestions,
  mdiLogoutVariant,
  mdiTheater
} from '@mdi/js'
import Icon from '@mdi/react'
import { useTeams } from 'features/team/teamHooks'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { USER_ROLE_TYPE } from '../constants'
import { logOutAPI } from '../features/account/auth/authAPI'
import { authState } from '../features/account/auth/authStore'
import { darkModeState } from '../features/account/user/userPrefStore'
import { ConfirmationDialog } from './ConfirmationDialog'
import MoonIcon from './MoonIcon'
import SunIcon from './SunIcon'
import TemplatesIcon from './TemplatesIcon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative',
      maxWidth: 360,
      overflow: 'hidden'
    },
    item: {
      paddingLeft: 24
    },
    subList: {
      borderLeft: `solid ${theme.palette.divider} 1px`
    },
    nested: {
      paddingLeft: theme.spacing(4),
      backgroundColor:
        theme.palette.type === 'dark'
          ? theme.palette.grey[900]
          : theme.palette.grey[50]
    },
    nestedItem: {
      paddingLeft: 20,
      backgroundColor:
        theme.palette.type === 'dark'
          ? theme.palette.grey[900]
          : theme.palette.grey[50]
    },
    toggle: {
      position: 'absolute',
      paddingLeft: 19,
      bottom: 55
    },
    logout: {
      position: 'absolute',
      bottom: 0
    },
    topProgress: {
      position: 'absolute',
      left: 0
    },
    bottomProgress: {
      color: theme.palette.divider
    }
  })
)

const typographyProps: TypographyProps = {
  noWrap: true,
  variant: 'button'
}

const routes = [
  // { path: '/create', icon: mdiCreation, label: 'Create' },
  { path: '/presentations', icon: mdiCollage, label: 'Presentations' },
  { path: '/templates', icon: mdiCodeBracesBox, label: 'Templates' },
  // {
  //   path: '/admin',
  //   subRoutes: [
  //     { path: '/team', icon: mdiAccountGroup, label: 'Team' },
  //     { path: '/showcases', icon: mdiTheater, label: 'Showcases' },
  //     {
  //       path: '/statistics',
  //       icon: mdiChartTimelineVariant,
  //       label: 'Statistics',
  //     },
  //   ],
  //   icon: mdiAccountSupervisor,
  //   label: 'Admin',
  // },
  { path: '/showcases', icon: mdiTheater, label: 'Showcases' },
  { path: '/team', icon: mdiAccountGroup, label: 'Team' },
  // {
  //   path: '/statistics',
  //   icon: mdiChartTimelineVariant,
  //   label: 'Statistics',
  // },
  { path: '/account', icon: mdiAccountCog, label: 'Account' },
  { path: '/faq', icon: mdiFrequentlyAskedQuestions, label: 'FAQ' }
]

export function Navigation(props: { sidebarCollapsed?: boolean }) {
  const { sidebarCollapsed } = props
  // const [openAdmin, setOpenAdmin] = React.useState(false);
  const [openLogout, setOpenLogout] = React.useState(false)
  const [darkMode, setDarkMode] = useRecoilState(darkModeState)
  const [authUser, setAuthUser] = useRecoilState(authState)
  const { teams } = useTeams(
    authUser
      ? {
          id: authUser.team_id as number,
          add_props:
            'file_size,file_size_h,max_file_size,max_file_size_h,count_experience_views_h'
        }
      : undefined
  )
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const classes = useStyles()

  const handleDarkModeToggle = React.useCallback(() => {
    setDarkMode(prev => !prev)
  }, [setDarkMode])

  // const handleClick = () => {
  //   setOpenAdmin(!openAdmin);
  // };

  const handleOpenLogout = () => {
    setOpenLogout(true)
  }
  const handleCloseLogout = () => {
    setOpenLogout(false)
  }

  const handleLogOut = async () => {
    try {
      await logOutAPI()
      setAuthUser(null)
      enqueueSnackbar('Have a nice day!')
      history.push('/auth/login')
    } catch (reason) {
      console.error(reason)
      enqueueSnackbar(JSON.stringify(reason), { variant: 'error' })
    }
  }

  const getStorageLabel = (file_size_h: string, max_file_size_h: string) => {
    return `${file_size_h} of ${max_file_size_h} used`
  }

  const getStoragePercent = (file_size: number, max_file_size: number) => {
    return Math.ceil((file_size / max_file_size) * 100)
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        open={openLogout}
        yesColor='primary'
        title='Log out'
        message='End your session?'
        onNoClick={handleCloseLogout}
        onYesClick={handleLogOut}
      />
      <List
        component='nav'
        className={classes.root}
        aria-label='site navigation'
      >
        {routes
          .filter(route =>
            authUser!.role_type_id >= USER_ROLE_TYPE.SUPER_CREATOR
              ? route.path !== '/showcases' && route.path !== '/team'
              : true
          )
          .map(route => (
            <div key={route.label}>
              <Tooltip
                title={<Typography variant='button'>{route.label}</Typography>}
                placement='right'
                arrow
                disableFocusListener={!sidebarCollapsed}
                disableHoverListener={!sidebarCollapsed}
                disableTouchListener={!sidebarCollapsed}
              >
                <ListItem
                  button
                  className={classes.item}
                  component={RouterLink}
                  // disabled={
                  //   ((authUser &&
                  //     authUser.role_type_id >= USER_ROLE_TYPE.SUPER_CREATOR) ||
                  //     getCustomerPlanName(customer) === "Basic" ||
                  //     getCustomerPlanName(customer) === "Personal") &&
                  //   (route.path === "/showcases" || route.path === "/team")
                  // }
                  selected={window.location.href.includes(route.path)}
                  to={route.path}
                >
                  <ListItemIcon>
                    {route.path === '/templates' ? (
                      <TemplatesIcon />
                    ) : (
                      <Icon path={route.icon} size={1} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={route.label}
                    primaryTypographyProps={typographyProps}
                  />
                </ListItem>
              </Tooltip>
            </div>
          ))}
        <Divider />
        {teams.length > 0 && (
          <Tooltip
            title={
              // <Typography variant='button'>{getStorageLabel(teams)}</Typography>
              <Typography variant='button'>
                {getStorageLabel(
                  teams[0].file_size_h!,
                  teams[0].max_file_size_h!
                )}
              </Typography>
            }
            placement='right'
            arrow
            disableFocusListener={!sidebarCollapsed}
            disableHoverListener={!sidebarCollapsed}
            disableTouchListener={!sidebarCollapsed}
          >
            <ListItem
              // className={sidebarCollapsed ? classes.item : undefined}
              style={
                !sidebarCollapsed
                  ? { paddingBottom: 0, paddingTop: 0 }
                  : { marginLeft: 4 }
              }
            >
              {sidebarCollapsed && (
                <ListItemIcon>
                  {/* <Icon path={mdiCloud} size={1} /> */}
                  <Box position='relative' display='inline-flex'>
                    <CircularProgress
                      className={classes.bottomProgress}
                      size='2rem'
                      variant='determinate'
                      value={100}
                    />
                    <CircularProgress
                      className={classes.topProgress}
                      size='2rem'
                      variant='determinate'
                      value={getStoragePercent(
                        teams[0].file_size!,
                        teams[0].max_file_size!
                      )}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position='absolute'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Typography
                        variant='caption'
                        component='div'
                        color='textSecondary'
                        style={{ cursor: 'default', fontSize: '0.65rem' }}
                      >{`${getStoragePercent(
                        teams[0].file_size!,
                        teams[0].max_file_size!
                      )}%`}</Typography>
                    </Box>
                  </Box>
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  <ListItem style={{ marginLeft: -8 }}>
                    <ListItemIcon>
                      <Icon path={mdiCloud} size={1} />
                    </ListItemIcon>
                    <div>Storage</div>
                  </ListItem>
                }
                secondary={
                  !sidebarCollapsed ? (
                    <div>
                      <LinearProgress
                        variant='determinate'
                        value={getStoragePercent(
                          teams[0].file_size!,
                          teams[0].max_file_size!
                        )}
                      />
                      <Box pt={0.5}>
                        {getStorageLabel(
                          teams[0].file_size_h!,
                          teams[0].max_file_size_h!
                        )}
                      </Box>
                    </div>
                  ) : null
                }
                primaryTypographyProps={typographyProps}
                secondaryTypographyProps={{ variant: 'caption' }}
              />
            </ListItem>
          </Tooltip>
        )}
        {/* {teams.length > 0 && (
          <Tooltip
            title={
              <Typography variant='button'>
                {teams.length > 0 &&
                  teams[0].count_experience_views_h &&
                  teams[0].count_experience_views_h + ' Views'}
              </Typography>
            }
            placement='right'
            arrow
            disableFocusListener={!sidebarCollapsed}
            disableHoverListener={!sidebarCollapsed}
            disableTouchListener={!sidebarCollapsed}
          >
            <ListItem
              className={classes.item}
              style={!sidebarCollapsed ? { paddingTop: 0 } : undefined}
            >
              <ListItemIcon>
                <Icon path={mdiEye} size={1} />
              </ListItemIcon>
              <ListItemText
                primary={
                  teams.length > 0 &&
                  teams[0].count_experience_views_h &&
                  teams[0].count_experience_views_h + ' Views'
                }
                // secondary={<LinearProgress variant='determinate' value={10} />}
                primaryTypographyProps={typographyProps}
              />
            </ListItem>
          </Tooltip>
        )} */}

        <FormGroup className={classes.toggle} row>
          <FormControlLabel
            control={
              <Tooltip
                title={<Typography variant='button'>Theme</Typography>}
                placement='right'
                arrow
                disableFocusListener={!sidebarCollapsed}
                disableHoverListener={!sidebarCollapsed}
                disableTouchListener={!sidebarCollapsed}
              >
                <Switch
                  color='primary'
                  checked={darkMode}
                  onChange={handleDarkModeToggle}
                  value='dark-toggle'
                  icon={<SunIcon />}
                  checkedIcon={<MoonIcon />}
                />
              </Tooltip>
            }
            label={
              <Typography
                variant='button'
                display='block'
                style={{ marginLeft: 14 }}
                noWrap
              >
                {darkMode ? 'Light Mode' : 'Dark Mode'}
              </Typography>
            }
          />
        </FormGroup>
        <Tooltip
          title={<Typography variant='button'>Log Out</Typography>}
          placement='right'
          arrow
          disableFocusListener={!sidebarCollapsed}
          disableHoverListener={!sidebarCollapsed}
          disableTouchListener={!sidebarCollapsed}
        >
          <ListItem
            className={`${classes.item} ${classes.logout}`}
            onClick={handleOpenLogout}
            button
          >
            <ListItemIcon>
              <Icon path={mdiLogoutVariant} size={1} />
            </ListItemIcon>
            <ListItemText
              primary='Log out'
              primaryTypographyProps={typographyProps}
            />
          </ListItem>
        </Tooltip>
      </List>
    </React.Fragment>
  )
}
