import useSWR, { SWRConfiguration } from "swr";

import { GET_AssetsQuery } from "../../../types";
import {
  AssetListResponse,
  AssetListQuery,
  GET_Assets,
} from "../../../xr-platform-api";
import { getMarkerURLAPI } from "../apis/xrMarkerAPI";

// import { MAX_MARKER_QUERY_WIDTH } from "../../../constants";
export function useXRMarkerURL(uuid?: string, width?: number) {
  const { data, error, isValidating, mutate } = useSWR(
    uuid ? ["getXRMarkerURL", uuid, width] : null,
    (key, uuid, width) => getMarkerURLAPI(uuid, { width: width }),
    { revalidateOnFocus: false }
  );

  return {
    markerURL: data,
    isLoading: !error && !data,
    error,
    isValidating,
    mutate,
  };
}

export const useAssetsList = (
  query?: GET_AssetsQuery,
  config?: SWRConfiguration<AssetListResponse>
) => {
  const { data, error, isValidating, mutate } = useSWR(
    query ? ["GET_AssetList", ...Object.values(query)] : null,
    (key, ...args) =>
      GET_Assets(
        args.reduce<AssetListQuery>(
          (prev, curr, i) => {
            const key = Object.keys(prev!)[i] as keyof AssetListQuery;
            prev[key] = curr;
            return prev;
          },
          { ...query }
        )
      ),
    config
  );

  return {
    assets: data?.assets ?? [],
    page: data?.total_count === 0 ? 0 : data?.page ?? 0,
    totalCount: data?.total_count ?? 0,
    isLoading: !data && !error,
    isValidating,
    error,
    mutate,
  };
};
