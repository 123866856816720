import { ExperienceSceneManager } from "./experience-scene-manager";
import React from "react";

export type ExperienceSceneManagerContextType = {
  experienceSceneManager: ExperienceSceneManager | null;
};

export const ExperienceSceneManagerContext =
  React.createContext<ExperienceSceneManagerContextType>({
    experienceSceneManager: null,
  });

export const useExperienceSceneManagerContext = () =>
  React.useContext(ExperienceSceneManagerContext).experienceSceneManager;
