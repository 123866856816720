import { ServerResponse } from "http";

import { GET_TagsQuery, Tag, TagType } from "../../types";

import axios from "axios";
import reportServerError from "../../helpers/reportServerError";
import { API_ROOT } from '../../config/BackendApi';

/**
 *
 * @param type The type of Tags to create ('a_tags' or 'e_tags')
 * @param tags Comma-separated, matches case-insensitive partials, OR-logic
 * @returns
 */
export const createTagsAPI = async (
  type: Exclude<TagType, "a_tag" | "e_tag">,
  tags: string
) => {
  try {
    const response = await axios.post<
      ServerResponse & { [t in typeof type]: Tag[] }
    >(`${API_ROOT}/api/v1/${type.slice(0, -1)}/${tags}/create`);
    return response.data[type];
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason);
      throw reason.response.data.error;
    }
    throw reason;
  }
};

/**
 *
 * @param type The type of Tags to create ('a_tags' or 'e_tags')
 * @param tag Single tag name to delete
 * @returns
 */
export const deleteTagAPI = async (
  type: Exclude<TagType, "a_tags" | "e_tags">,
  tag: string
) => {
  try {
    const response = await axios.delete<
      ServerResponse & { [t in typeof type]: Tag }
    >(`${API_ROOT}/api/v1/${type}/${tag}/delete`);
    return response.data[type];
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason);
      throw reason.response.data.error;
    }
    throw reason;
  }
};

export const getTagAPI = async (
  type: Exclude<TagType, "a_tags" | "e_tags">,
  tag: string
) => {
  try {
    const response = await axios.get<
      ServerResponse & { [t in typeof type]: Tag }
    >(`${API_ROOT}/api/v1/${type}/${tag}`);
    return response.data[type];
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason);
      throw reason.response.data.error;
    }
    throw reason;
  }
};

export const getTagsAPI = async (
  type: Exclude<TagType, "a_tag" | "e_tag">,
  query?: GET_TagsQuery
) => {
  try {
    const response = await axios.get<
      ServerResponse & { [t in typeof type]: Tag[] }
    >(`${API_ROOT}/api/v1/${type}`, { params: query });
    return response.data[type];
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason);
      throw reason.response.data.error;
    }
    throw reason;
  }
};
