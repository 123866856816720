import "./input.css";

import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Token } from "@stripe/stripe-js";
import { useSnackbar } from "notistack";
import React from "react";

import { createTaxExemptProofs as createTaxExemptProofsAPI } from "./stripeAPI";
import { StripeInput } from "./StripeInput";

type Props = {
  onSubmit: (token: Token, exempt_tax?: boolean) => void;
  isLoading?: boolean;
  paymentSource?: any;
} & Omit<DialogProps, "onSubmit">;

export const PaymentFormDialog: React.FC<Props> = ({
  onSubmit,
  isLoading,
  paymentSource,
  ...dialogProps
}) => {
  const [fullname, setFullname] = React.useState(
    Boolean(paymentSource) ? paymentSource.name : ""
  );
  const [zip, setZip] = React.useState(
    Boolean(paymentSource) ? paymentSource.address_zip : ""
  );
  const [toggleTax, setToggleTax] = React.useState(false);
  const fileInput = React.useRef<HTMLInputElement>();

  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();

  const handleNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFullname(e.target.value);
  };

  const handleZipChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setZip(e.target.value);
  };

  const handleToggleChange = () => {
    setToggleTax(prev => !prev);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement === null) return;

    try {
      const { token } = await stripe.createToken(cardElement, {
        name: fullname,
        address_zip: zip,
      });

      let exemptTax = false;
      if (fileInput.current?.files?.length) {
        await createTaxExemptProofsAPI(Array.from(fileInput.current.files));
        exemptTax = true;
      }

      if (token) onSubmit(token, exemptTax);
    } catch (reason) {
      console.error(reason);
      enqueueSnackbar(JSON.stringify(reason), { variant: "error" });
    }
  };

  return (
    <Dialog {...dialogProps}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id="payment-source-form">
          {Boolean(paymentSource)
            ? "Update Payment Source"
            : "Add Payment Source"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name on Credit Card"
            name="ccfullname"
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
            margin="normal"
            placeholder="First Middle Last"
            value={fullname}
            onChange={handleNameChange}
          />
          <TextField
            label="Credit Card Number"
            name="ccnumber"
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
            margin="normal"
            InputProps={{
              //@ts-ignore
              inputComponent: StripeInput,
              inputProps: {
                component: CardNumberElement,
              },
            }}
          />
          <TextField
            label="Expiration Date"
            name="ccexp"
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
            margin="normal"
            InputProps={{
              //@ts-ignore
              inputComponent: StripeInput,
              inputProps: {
                component: CardExpiryElement,
              },
            }}
          />
          <TextField
            label="CVC"
            name="cvc"
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
            margin="normal"
            InputProps={{
              //@ts-ignore
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcElement,
              },
            }}
          />
          <TextField
            label="Zip Code"
            name="zipcode"
            variant="outlined"
            type="number"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{}}
            margin="normal"
            placeholder="Zip Code"
            value={zip}
            onChange={handleZipChange}
          />
          <FormControlLabel
            control={
              <Switch checked={toggleTax} onChange={handleToggleChange} />
            }
            label="Tax Exempt"
          />
          <Collapse in={toggleTax}>
            <TextField
              label="Tax Exempt Proof"
              InputLabelProps={{ shrink: true }}
              type="file"
              margin="normal"
              inputRef={fileInput}
              inputProps={{ multiple: true }}
            />
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button
            id="btn-close-edit-cc"
            // color="secondary"
            disabled={isLoading}
            onClick={() => {
              if (dialogProps.onClose) dialogProps.onClose({}, "escapeKeyDown");
            }}
          >
            Cancel
          </Button>
          <Button
            id="btn-edit-cc"
            type="submit"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size="1.5rem" />
            ) : (
              "Submit"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
