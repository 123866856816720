import {
  Box,
  Divider,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Layout, {
  getCollapseBtn,
  getContent,
  getDrawerSidebar,
  getHeader,
  getSidebarContent,
  getSidebarTrigger,
  Root,
} from "@mui-treasury/layout";
import React from "react";
import styled from "styled-components";

import { AppIconAvatar } from "./AppIconAvatar";
import { CookieConsent } from "./CookieConsent";
import { LoadingScreen } from "./LoadingScreen";
import { Navigation } from "./Navigation";

const Header = getHeader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const Content = getContent(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const SidebarContent = getSidebarContent(styled);
const CollapseBtn = getCollapseBtn(styled);

const Main = styled("div")({ padding: "0 48px" });

const scheme = Layout();

// Basically the fixed layout with some small tweaks.
scheme.configureHeader(builder => {
  builder
    .registerConfig("xs", {
      position: "fixed",
      initialHeight: 62,
    })
    .registerConfig("md", {
      position: "relative",
      initialHeight: 64,
    });
});
scheme.configureEdgeSidebar(builder => {
  builder
    .create("primarySidebar", {
      anchor: "left",
    })
    .registerTemporaryConfig("xs", {
      width: 240,
    })
    .registerPermanentConfig("md", {
      width: 240,
      collapsible: true,
      collapsedWidth: 72,
    });
});
scheme.enableAutoCollapse("primarySidebar", "md");

/**
 * Main layout used for authenticated part of the site.
 * Handles lazy loading in the Content section.
 * @param props.children Item to render in the Content section.
 */
export function MainLayout(props: { children?: React.ReactNode }) {
  const { children } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Root scheme={scheme} theme={theme}>
      {({ state: { sidebar } }) => (
        <React.Fragment>
          <CookieConsent />
          {!match && (
            <Header variant="elevation" elevation={4}>
              <Toolbar disableGutters={false}>
                <SidebarTrigger sidebarId="primarySidebar" color="inherit" />
                <Typography variant="h4">Post Reality</Typography>
              </Toolbar>
            </Header>
          )}
          <DrawerSidebar
            sidebarId={"primarySidebar"}
            PaperProps={{ style: { overflow: "hidden" }, elevation: 4 }}
          >
            <Box
              margin="24px auto 0px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box mb={1.5}>
                <AppIconAvatar
                  width={
                    sidebar.primarySidebar.collapsed
                      ? sidebar.primarySidebar.open
                        ? 18
                        : 6
                      : 18
                  }
                  height={
                    sidebar.primarySidebar.collapsed
                      ? sidebar.primarySidebar.open
                        ? 18
                        : 6
                      : 18
                  }
                />
              </Box>
              <Typography variant="caption" align="center" paragraph>
                PostReality &copy; {new Date().getFullYear()}
              </Typography>
            </Box>
            <Divider />
            <SidebarContent>
              <Navigation sidebarCollapsed={sidebar.primarySidebar.collapsed} />
            </SidebarContent>
            <CollapseBtn color="inherit" />
          </DrawerSidebar>
          <Content>
            <Main style={{ height: !match ? "calc(100vh - 64px)" : undefined }}>
              <React.Suspense fallback={<LoadingScreen width="100%" />}>
                {children}
              </React.Suspense>
            </Main>
          </Content>
        </React.Fragment>
      )}
    </Root>
  );
}
