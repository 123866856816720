import { Token } from "@stripe/stripe-js";
// import axios from 'axios';
import { ServerResponse } from "../../../types";
// import { axios } from "../../../helpers/configAxios";
import axios from "axios";
import reportServerError from "../../../helpers/reportServerError";
import { API_ROOT } from '../../../config/BackendApi'

export const getCustomerAPI = async () => {
  try {
    const response = await axios.get<ServerResponse & { customer: any }>(
      `${API_ROOT}/api/v1/customer`
    );
    return response.data.customer;
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason);
      throw new Error(reason.response.data.error);
    }
    throw reason;
  }
};

export const updatePaymentSourceAPI = async (
  token: Token,
  email: string,
  tax_exempt: "none" | "exempt" | "reverse" = "none"
) => {
  try {
    const formData = new FormData();
    formData.append("stripeToken", token.id);
    formData.append("stripeEmail", email);
    formData.append("tax_exempt", tax_exempt as string);
    const response = await axios.put<ServerResponse & { customer: any }>(
      `${API_ROOT}/api/v1/update-payment-source`,
      formData,
      { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
    );
    return response.data.customer;
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason);
      throw new Error(reason.response.data.error);
    }
    throw reason;
  }
};

export const updateSubscriptionAPI = async (
  plan_id: string,
  quantity?: number,
  coupon_id?: string
) => {
  try {
    const response = await axios.put<ServerResponse & { subscription: any }>(
      `${API_ROOT}/api/v1/subscription`,
      { subscription_items: [{ plan_id, quantity }], coupon_id }
    );
    return response.data.subscription;
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason);
      throw new Error(reason.response.data.error);
    }
    throw reason;
  }
};

export const getPlansListAPI = async () => {
  try {
    const response = await axios.get<ServerResponse & { plans: any }>(
        `${API_ROOT}/api/v1/list-plans`
    );
    return response.data.plans;
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason);
      throw new Error(reason.response.data.error);
    }
    throw reason;
  }
};

export const createInvoicePreviewAPI = async (
  plan_id: string,
  quantity?: number,
  coupon_id?: string
) => {
  try {
    const response = await axios.post<ServerResponse & { invoice: any }>(
        `${API_ROOT}/api/v1/invoice_preview`,
      { subscription_items: [{ plan_id, quantity }], coupon_id }
    );
    return response.data.invoice;
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason);
      throw new Error(reason.response.data.error);
    }
    throw reason;
  }
};

export const deleteSavedPaymentSourceAPI = async (id: string) => {
  try {
    const response = await axios.delete(`${API_ROOT}/api/v1/delete-card/${id}`);
    return response.data;
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason);
      throw new Error(reason.response.data.error);
    }
    throw reason;
  }
};

export type TaxExemptProof = {
  created: string; // datetime
  modified: string; // datetime
  name: string | null; // derived from file.filename
  url: string;
  user_billing_id: number;
  uuid: string;
};

export const createTaxExemptProofs = async (files: File[]) => {
  try {
    const formData = new FormData();
    files.forEach(file => formData.append(file.name, file));

    const response = await axios.post<
      ServerResponse & { tax_exempt_proofs: TaxExemptProof[] }
    >(`${API_ROOT}/api/v1/tax_exempt_proofs/create`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.tax_exempt_proofs;
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason);
      throw new Error(reason.response.data.error);
    }
    throw reason;
  }
};
