import { AxiosResponse } from 'axios'
import { XRPServerResponse } from './types/xrp'

import TagManager from 'react-gtm-module'

const reportServerError = (reason: any) => {
  if (process.env.NODE_ENV !== 'production') return
  TagManager.dataLayer({
    dataLayer: {
      event: 'server_error',
      error_code: reason.response.status,
      error_text: reason.response.statusText,
      request_url: reason.config.url
    }
  })
}

export const isBackendError = (
  reason: any
): reason is { response: AxiosResponse<XRPServerResponse> } => {
  return reason.response.data.error
}

export const getAuthorizationHeader = (token: string) => ({
  Authorization: `Bearer ${token}`
})

export const throwNetworkError = (r: unknown) => {
  // if (r instanceof CanceledError) throw r
  if (isBackendError(r)) {
    reportServerError(r)
    throw new Error(r?.response?.data?.error ?? undefined)
  }
  throw r
}

export const getHeadPageTitle = (title: string) =>
  `${title} - Post Reality Studio`
