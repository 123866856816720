import {
  AppBar,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  fade,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  mdiBasket,
  mdiCloseCircleOutline,
  mdiCube,
  mdiFilmstrip,
  mdiImage,
  mdiMagnify,
  mdiMusic,
  mdiWallet,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Token } from "@stripe/stripe-js";
import { useSnackbar } from "notistack";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { Pagination } from "../../components/Pagination";
import { USER_ROLE_TYPE } from "../../constants";
import { Asset } from "../../models/exp-entities";
import metamaskD from "../../resources/img/Metamask_Dark.svg";
import metamaskL from "../../resources/img/Metamask_Light.svg";
import { XRAsset } from "../../types/xrEntities";
import { AssetListQuery, POST_PurchaseStoreAsset } from "../../xr-platform-api";
import { authState } from "../account/auth/authStore";
import { PaymentFormDialog } from "../account/stripe/PaymentFormDialog";
import { updatePaymentSourceAPI } from "../account/stripe/stripeAPI";
import { getCustomerPaymentSource } from "../account/stripe/stripeHelpers";
import { useStripeCustomer } from "../account/stripe/useStripeCustomer";
import { useTags } from "../tag/tagHooks";
import { updateExperienceAsset } from "../xr_experience/experience-manager";
import { useExperienceSceneManagerContext } from "../xr_experience/experience-scene-manager-context";
import {
  expAssetState,
  experienceState,
  selectedAssetIDState,
} from "../xr_experience/experience-store";
import { useAssetsList } from "../xr_experience/hooks/assetHooks";
import { AssetCard } from "./AssetCard";
import { generateSessionID } from "./assetUtilities";

const TEAM_ASSET_PER_PAGE = 12;
const STORE_ASSET_PER_PAGE = 12;

const categories = [
  { label: "Images", icon: mdiImage, type: "image" },
  { label: "Videos", icon: mdiFilmstrip, type: "video" },
  { label: "Audio", icon: mdiMusic, type: "audio" },
  { label: "Models", icon: mdiCube, type: "3d" },
  { label: "Purchased", icon: mdiBasket, type: undefined },
  { label: "NFT", icon: mdiWallet, type: undefined },
];

const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(4),
      display: "flex",
    },
    actions: {
      // backgroundColor: theme.palette.background.default,
      padding: 0,
      borderTop: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[2],
    },
    paper: {
      minWidth: "85%",
      minHeight: "85%",
    },
    title: {
      // flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    divider: {
      marginLeft: theme.spacing(3),
    },
    tabs: {
      flexGrow: 1,
    },
    tab: {
      minWidth: 80,
    },
    search: {
      // position: 'relative',
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      // position: 'absolute',
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      paddingLeft: "0.5em",
      transition: theme.transitions.create("width"),
      width: "20ch",
      // width: '100%',
      // [theme.breakpoints.up('sm')]: {
      //   width: '15ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    listBox: {
      border: `solid 1px ${theme.palette.divider}`,
    },
    confirmation: {
      zIndex: 9000,
    },
    ntf: {
      width: "100%",
      // height: "100%",
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    metamask: {
      margin: theme.spacing(4, 0, 0),
    },
  })
);

type Props = { type?: string; replaceCurrent?: boolean } & Omit<
  DialogProps,
  "children" | "BackdropProps" | "style" | "PaperProps"
>;

export const AssetStore: React.FC<Props> = ({
  type,
  replaceCurrent,
  container,
  open,
  ...dialogProps
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const [openCCForm, setOpenCCForm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const authUser = useRecoilValue(authState);
  const [editor, setEditor] = useRecoilState(experienceState);
  const [tab, setTab] = React.useState(0);
  const [searchInput, setSearchInput] = React.useState<string[]>([]);
  const [toPurchase, setToPurchase] = React.useState<XRAsset | null>(null);
  const expSceneManager = useExperienceSceneManagerContext();
  const selectedID = useRecoilValue(selectedAssetIDState);
  const assetReplace = useRecoilValue(
    expAssetState({ session_id: selectedID })
  );

  // const tabs =
  //   authUser && authUser.role_type_id < USER_ROLE_TYPE.SUPER_CREATOR
  //     ? categories
  //     : categories.filter(c => c.label === "Purchased" || c.label === "NFT");

  const { tags } = useTags("a_tags");
  const {
    customer,
    mutate: mutateCustomer,
    isLoading,
    // isValidating,
  } = useStripeCustomer({
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  const [storeAssetsQuery, setStoreAssetsQuery] =
    React.useState<AssetListQuery>({
      add_props: "belongs_to_my_teams,price,preview_image_urls",
      is_for_store: true,
      name: undefined,
      order_by: "name",
      page: 1,
      per_page: STORE_ASSET_PER_PAGE,
      reverse: false,
      tag: "premium",
      type: categories[0].type,
    });

  const prefetchStoreAssetsQuery = React.useMemo(
    () => ({ ...storeAssetsQuery, page: storeAssetsQuery.page! + 1 }),
    [storeAssetsQuery]
  );

  const [teamAssetsQuery, setTeamAssetsQuery] = React.useState<AssetListQuery>({
    add_props: "belongs_to_my_teams,preview_image_urls",
    name: undefined,
    order_by: undefined,
    page: 1,
    per_page: TEAM_ASSET_PER_PAGE,
    reverse: true,
    tag: "premium",
    type: undefined,
    team_id: authUser!.team_id,
  });

  const prefetchTeamAssetsQuery = React.useMemo(
    () => ({ ...teamAssetsQuery, page: teamAssetsQuery.page! + 1 }),
    [teamAssetsQuery]
  );

  const {
    assets: storeAssets,
    page: storeAssetsPage,
    totalCount: totalStoreAssetsCount,
  } = useAssetsList(storeAssetsQuery);
  useAssetsList(prefetchStoreAssetsQuery);

  const {
    assets: teamAssets,
    page: teamAssetsPage,
    totalCount: totalTeamAssetsCount,
    mutate: mutateUserAssets,
  } = useAssetsList(teamAssetsQuery);
  useAssetsList(prefetchTeamAssetsQuery);

  const handleTagSearch = (event: React.ChangeEvent<{}>, value: string[]) => {
    setSearchInput(value);
    setStoreAssetsQuery(prev => ({
      ...prev,
      page: 1,
      tag: value.length ? value.join() : undefined,
    }));
    setTeamAssetsQuery(prev => ({
      ...prev,
      page: 1,
      tag: value.length ? value.join() : undefined,
    }));
  };

  const handleTagDelete = (tagToDelete: string) => () => {
    const newSearch = searchInput.filter(t => t !== tagToDelete);
    setSearchInput(newSearch);
    setStoreAssetsQuery(prev => ({
      ...prev,
      tag: newSearch.length ? newSearch.join() : undefined,
    }));
  };

  const handleStoreAssetsPageTurn = (pageOffset: number) => {
    setStoreAssetsQuery(prev => ({ ...prev, page: prev.page! + pageOffset }));
  };
  const handleTeamAssetsPageTurn = (pageOffset: number) => {
    setTeamAssetsQuery(prev => ({ ...prev, page: prev.page! + pageOffset }));
  };

  const handleBuy = async (assetToBuy: XRAsset) => {
    const price = assetToBuy.price ?? 0;
    if (authUser!.role_type_id >= USER_ROLE_TYPE.SUPER_CREATOR && price > 0) {
      enqueueSnackbar("Only account admins can make purchases.", {
        variant: "error",
      });
      return;
    }
    if (assetToBuy.price !== undefined && assetToBuy.price === 0) {
      if (isLoading) return;
      let key: any;
      try {
        setLoading(true);
        key = enqueueSnackbar(`Importing item`, {
          variant: "info",
          persist: true,
        });
        const { asset } = await POST_PurchaseStoreAsset(assetToBuy.uuid);
        handleImport(asset);
        mutateUserAssets();
      } catch (reason) {
        console.error(reason);
      } finally {
        setLoading(false);
        closeSnackbar(key);
      }
    } else if (getCustomerPaymentSource(customer) === undefined) {
      setOpenCCForm(true);
      return;
    } else {
      setToPurchase(assetToBuy);
    }
  };

  const handleBuyConfirmation = async () => {
    if (toPurchase === null || isLoading) return;
    let key: any;
    try {
      setLoading(true);
      key = enqueueSnackbar(`Processing purchase`, {
        variant: "info",
        persist: true,
      });
      const { asset } = await POST_PurchaseStoreAsset(toPurchase.uuid);
      handleImport(asset);
      setToPurchase(null);

      // enqueueSnackbar(`Item purchased`, { variant: "info" });
      mutateUserAssets();
    } catch (reason) {
      console.error(reason);
    } finally {
      setLoading(false);
      closeSnackbar(key);
    }
  };

  const handleBuyRejection = () => {
    if (isLoading) return;
    setToPurchase(null);
  };

  const handleImport = async (toImport: Asset) => {
    toImport = { ...toImport };
    if (toImport.type === "audio") {
      toImport.original_width = 0.25;
      toImport.original_height = 0.25;
    }
    // toImport.position = {
    //   x: 0,
    //   y: 0,
    //   z: toImport.type === "3d" ? -0.05 : -0.01,
    // };
    // toImport.rotation = { x: 0, y: 0, z: 0, w: 1 };
    // toImport.scale = { x: 1, y: 1, z: 1 };
    // toImport.strPos = ["0", "0", toImport.type === "3d" ? "-0.05" : "-0.01"];
    // toImport.strRot = ["0", "0", "0", "1"];
    // toImport.strScl = ["1", "1", "1"];
    // toImport.session_id = generateSessionID();
    toImport.position =
      assetReplace && replaceCurrent
        ? assetReplace.position
        : {
            x: 0,
            y: 0,
            z: toImport.type === "3d" ? -0.05 : -0.01,
          };
    toImport.rotation =
      assetReplace && replaceCurrent
        ? assetReplace.rotation
        : { x: 0, y: 0, z: 0, w: 1 };
    toImport.scale =
      assetReplace && replaceCurrent
        ? assetReplace.scale
        : { x: 1, y: 1, z: 1 };
    toImport.strPos =
      assetReplace && replaceCurrent
        ? assetReplace.strPos
        : ["0", "0", toImport.type === "3d" ? "-0.05" : "-0.01"];
    toImport.strRot =
      assetReplace && replaceCurrent
        ? assetReplace.strRot
        : ["0", "0", "0", "1"];
    toImport.strScl =
      assetReplace && replaceCurrent ? assetReplace.strScl : ["1", "1", "1"];
    toImport.session_id =
      assetReplace && replaceCurrent
        ? assetReplace.session_id
        : generateSessionID();
    // const update = {
    //   ...editor!,
    //   asset_uuids: [...(editor?.asset_uuids ?? []), toImport.uuid],
    //   asset_transform_info: [...(editor?.asset_transform_info ?? []), toImport],
    // };
    const update =
      assetReplace && replaceCurrent
        ? {
            ...updateExperienceAsset(editor!, toImport),
            asset_uuids: editor!
              .asset_uuids!.filter(uuid => uuid !== assetReplace.uuid)
              .concat([toImport.uuid]),
          }
        : {
            ...editor!,
            asset_uuids: [...(editor?.asset_uuids ?? []), toImport.uuid],
            asset_transform_info: [
              ...(editor?.asset_transform_info ?? []),
              toImport,
            ],
          };
    setEditor(update);
    expSceneManager?.updateSceneWithStateAsync(update);
    if (dialogProps.onClose) dialogProps.onClose({}, "escapeKeyDown");
    enqueueSnackbar(`${toImport.name} added`, { variant: "info" });
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setStoreAssetsQuery(prev => ({
      ...prev,
      page: 1,
      type: categories[newValue].type,
    }));
    setTab(newValue);
  };

  const handleClose = () => {
    if (dialogProps.onClose) {
      dialogProps.onClose({}, "escapeKeyDown");
    }
  };

  const handlePaymentFormSubmit = (token: Token) => {
    mutateCustomer(async () => {
      try {
        setLoading(true);
        const udpatedCustomer = await updatePaymentSourceAPI(
          token,
          authUser!.email!
        );
        // setToEdit((prev) => ({ ...initEditState, plan: prev.plan, cc: false }));
        setOpenCCForm(false);
        enqueueSnackbar("Payment source added", { variant: "success" });
        return udpatedCustomer;
      } catch (reason) {
        console.error(reason);
        enqueueSnackbar(JSON.stringify(reason), { variant: "error" });
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <React.Fragment>
      <PaymentFormDialog
        paymentSource={getCustomerPaymentSource(customer)}
        onSubmit={handlePaymentFormSubmit}
        open={openCCForm}
        isLoading={loading}
        onClose={() => setOpenCCForm(false)}
      />
      <ConfirmationDialog
        open={Boolean(toPurchase)}
        title="Confirm Purchase"
        message="You sure?"
        yesColor="primary"
        onYesClick={handleBuyConfirmation}
        onNoClick={handleBuyRejection}
        isLoading={isLoading}
      />
      <Dialog
        BackdropProps={{
          style: container ? { position: "absolute" } : undefined,
        }}
        style={{ position: container ? "absolute" : undefined, minWidth: 630 }}
        PaperProps={{ classes: { root: styles.paper } }}
        open={open && !openCCForm}
        {...dialogProps}
      >
        <AppBar component="div" position="sticky" color="primary">
          <Toolbar variant="dense" /* disableGutters */>
            <Typography className={styles.title} variant="h6">
              {authUser && authUser.role_type_id >= USER_ROLE_TYPE.SUPER_CREATOR
                ? "Team Library"
                : "Media Library"}
            </Typography>
            {/* <Divider
              className={styles.divider}
              orientation="vertical"
              flexItem
            /> */}
            <Tabs
              className={styles.tabs}
              value={tab}
              variant="scrollable"
              scrollButtons="on"
              onChange={handleTabChange}
            >
              {categories.map((c, i) => (
                <Tab
                  className={styles.tab}
                  key={i}
                  label={c.label}
                  wrapped
                  icon={<Icon path={c.icon} size={1} />}
                />
              ))}
            </Tabs>

            {authUser && authUser.role_type_id < USER_ROLE_TYPE.SUPER_CREATOR && (
              <Autocomplete
                multiple
                id="asset-search"
                options={tags.map(t => t.tag)}
                value={searchInput}
                onChange={handleTagSearch}
                ListboxComponent={params => (
                  <Paper {...params} className={styles.listBox} />
                )}
                renderInput={params => (
                  <div className={styles.search} ref={params.InputProps.ref}>
                    <div className={styles.searchIcon}>
                      <Icon path={mdiMagnify} size={1} />
                    </div>
                    <div className={styles.chips}>
                      {searchInput.map(item => (
                        <Chip
                          className={styles.chip}
                          key={item}
                          tabIndex={-1}
                          label={item}
                          size="small"
                          color="primary"
                          onDelete={handleTagDelete(item)}
                        />
                      ))}
                    </div>
                    <InputBase
                      placeholder="Search by tags..."
                      classes={{
                        root: styles.inputRoot,
                        input: styles.inputInput,
                      }}
                      type="text"
                      inputProps={{ ...params.inputProps }}
                    />
                  </div>
                )}
              />
            )}
            <IconButton
              color="inherit"
              size="small"
              onClick={handleClose}
              style={{ margin: "0 0 0 16px" }}
            >
              <Icon path={mdiCloseCircleOutline} size={1.2} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={styles.content}>
          {categories[tab].label !== "Purchased" &&
            categories[tab].label !== "NFT" && (
              <Grid id="assets-for-purchase" container spacing={2}>
                {storeAssets.map(a => (
                  <Grid key={a.id} item xs={6} sm={4} md={3} lg={2}>
                    <AssetCard
                      asset={a}
                      forStore
                      onPurchase={handleBuy}
                      onImport={handleImport}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          {categories[tab].label === "Purchased" && (
            <Grid id="purchased-assets" container spacing={2}>
              {teamAssets.map(a => (
                <Grid key={a.id} item xs={6} sm={4} md={3} lg={2}>
                  <AssetCard asset={a} onImport={handleImport} />
                </Grid>
              ))}
            </Grid>
          )}
          {categories[tab].label === "NFT" && (
            <div className={styles.ntf}>
              <Typography align="center" variant="h3" paragraph>
                COMING SOON
              </Typography>
              <Typography align="center" variant="h5">
                <i>Connect your MetaMask wallet</i>
              </Typography>
              <Typography align="center" variant="h5" paragraph>
                <i>to import your NFT media assets to Post Reality</i>
              </Typography>
              <img
                className={styles.metamask}
                src={theme.palette.type === "dark" ? metamaskL : metamaskD}
                alt="metamask logo"
              />
            </div>
          )}
        </DialogContent>
        {categories[tab].label !== "NFT" && (
          <DialogActions className={styles.actions}>
            <Pagination
              page={
                categories[tab].label === "Purchased"
                  ? teamAssetsPage
                  : storeAssetsPage
              }
              perPage={
                categories[tab].label === "Purchased"
                  ? TEAM_ASSET_PER_PAGE
                  : STORE_ASSET_PER_PAGE
              }
              totalCount={
                categories[tab].label === "Purchased"
                  ? totalTeamAssetsCount
                  : totalStoreAssetsCount
              }
              onPageTurn={
                categories[tab].label === "Purchased"
                  ? handleTeamAssetsPageTurn
                  : handleStoreAssetsPageTurn
              }
            />
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};
