import TagManager, { TagManagerArgs } from "react-gtm-module";

const tagManagerArgs: TagManagerArgs = {
  gtmId: "GTM-PQBNNNS",
  // dataLayerName: ""
};

const initGTM = () => {
  TagManager.initialize(tagManagerArgs);
};

export default initGTM;
