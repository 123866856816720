import useSWR from 'swr';

import { GET_TagsQuery, TagType } from '../../types';
import { getTagsAPI } from './tagAPI';

export const useTags = (
  type: Exclude<TagType, 'a_tag' | 'e_tag'>,
  query?: GET_TagsQuery
) => {
  const { data, error, isValidating, mutate } = useSWR(
    ['getTags', type, query],
    (key, type, query) => getTagsAPI(type, query)
  );
  return {
    tags: data ?? [],
    error,
    isLoading: !data && !error,
    isValidating,
    mutate,
  };
};
