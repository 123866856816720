import {
  BASIC_PLAN_NAME,
  MONTHLY_PLAN_NAME,
  SEMESTER_PLAN_NAME,
  YEARLY_PLAN_NAME
} from '../../../constants'

export type StripePlans = {
  yearly: any
  semester: any
  monthly: any
  basic: any
}

export const createStripePlans = (rawPlanData: any) => {
  if (Array.isArray(rawPlanData)) {
    return rawPlanData.reduce<StripePlans>(
      (accum, curr, index, arr) => {
        switch (curr.nickname) {
          case 'personal/team every 12 months':
            accum.yearly = curr
            break
          case 'personal/team every 4 months':
            accum.semester = curr
            break
          case 'personal/team':
            accum.monthly = curr
            break
          case 'basic':
            accum.basic = curr
            break
          default:
            break
        }
        return accum
      },
      {
        yearly: null,
        semester: null,
        monthly: null,
        basic: null
      }
    )
  }
  return null
}

export const getPlanDescription = (
  id: string,
  seats: number,
  plans: StripePlans
) => {
  const type = seats > 1 ? 'TEAM' : 'PERSONAL'
  let description = type === 'TEAM' ? `${type} ${seats}` : type
  const plan = Object.values(plans).find(p => p.id === id)
  console.log(plan)
  switch (plan.nickname) {
    case YEARLY_PLAN_NAME:
      return 'Yearly ' + description
    case SEMESTER_PLAN_NAME:
      return 'Every 4 Months ' + description
    case MONTHLY_PLAN_NAME:
      return 'Monthly ' + description
    case BASIC_PLAN_NAME:
      return 'BASIC'
  }
}

export const getCustomerSubscription = (customer: any) => {
  return customer?.subscriptions?.data[0]
}

export const getCustomerPlan = (customer: any) => {
  return getCustomerSubscription(customer)?.plan
}

export const getCustomerSeatQuantity = (customer: any) => {
  return getCustomerSubscription(customer)?.quantity
}

export const getCustomerPlanName = (
  customer: any
): 'Basic' | 'Personal' | 'Team' | null => {
  let tier = getCustomerSeatQuantity(customer)
  let nickname = getCustomerPlan(customer)?.nickname ?? null
  if (nickname === 'basic') return 'Basic'
  if (nickname !== null && (nickname as string).includes('personal/team')) {
    if (tier === 1) return 'Personal'
    if (tier > 1) return 'Team'
  }
  return null
}

export const getCutomerBillingCycle = (customer: any) => {
  const cusPlan = getCustomerPlan(customer)
  if (Boolean(cusPlan)) {
    if (cusPlan.interval === 'month') {
      return cusPlan.interval_count as number
    } else if (cusPlan.interval === 'year') {
      return 12
    }
  }
}

export const getCustomerSubsciptionPhases = (customer: any) => {
  console.log(getCustomerSubscription(customer)?.schedule?.phases)
  return getCustomerSubscription(customer)?.schedule?.phases
}

export const getCustomerCurrentSubscriptionPhase = (customer: any) => {
  return getCustomerSubsciptionPhases(customer)[0]
}

export const getCustomerUpcomingSubscriptionPhase = (customer: any) => {
  return getCustomerSubsciptionPhases(customer)[1]
}

export const getCustomerPaymentSource = (customer: any) => {
  return customer?.sources?.data ? customer?.sources?.data[0] : undefined
}

export const customerHasPaymentSource = (customer: any) => {
  return customer?.sources?.data ? true : false
}

export const getCustomerInvoice = (customer: any) => {
  return getCustomerSubscription(customer)?.latest_invoice
}

export const getPersonalTier = (plans: StripePlans, cycle: number) => {
  let plan: any = null
  switch (cycle) {
    case 1:
      plan = plans.monthly
      break
    case 4:
      plan = plans.semester
      break
    case 12:
      plan = plans.yearly
      break
    default:
      break
  }
  return plan?.tiers[0]
}

export const getTeamTier = (
  plans: StripePlans,
  cycle: number,
  seats: number
) => {
  let plan: any = null
  switch (cycle) {
    case 1:
      plan = plans.monthly
      break
    case 4:
      plan = plans.semester
      break
    case 12:
      plan = plans.yearly
      break
    default:
      break
  }
  return plan?.tiers.find((tier: any) => tier.up_to === seats)
}
