import React from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch
} from 'react-router-dom'
import { useRecoilValueLoadable } from 'recoil'

import { authState } from '../features/account/auth/authStore'
import AssetStorePage from '../features/asset/AssetStorePage'
import { LoadingScreen } from '../components/LoadingScreen'
import { MainLayout } from '../components/MainLayout'

const AuthenticationPage = React.lazy(() => import('./AuthenticationPage'))
const StatisticsPage = React.lazy(
  () => import('../features/analytics/Statistics')
)
const AccountPage = React.lazy(() => import('./AccountPage'))

const MyPresentationsPage = React.lazy(() => import('./MyExperiencesPage'))
const TemplatesPage = React.lazy(
  () => import('../features/xr_experience/components/TemplatesPage')
)
const TeamPage = React.lazy(() => import('./TeamPage'))
const FaqPage = React.lazy(() => import('./FaqPage'))
const ShowcasePage = React.lazy(() => import('./ShowcasePage'))
const ManageShowcasesPage = React.lazy(() => import('./ManageShowcasesPage'))

const VerifyAccountPage = React.lazy(() => import('./VerifyAccountPage'))
const ForgotPasswordPage = React.lazy(() => import('./ForgotPasswordPage'))
const ResetPasswordPage = React.lazy(() => import('./ResetPasswordPage'))

const PresentationEditorPage = React.lazy(
  () => import('./PresentationEditorPage')
)
const PresentationViewerPage = React.lazy(
  () => import('./PresentationViewerPage')
)

const DeleteAccountPage = React.lazy(() => import('./DeleteAccountPage'))

const ProtectedRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const authUserLoadable = useRecoilValueLoadable(authState)

  switch (authUserLoadable.state) {
    case 'hasValue':
      if (authUserLoadable.contents?.role_type_id === 10) {
        return <Redirect to='/statistics' />
      }
      // if (authUserLoadable.contents?.role_type_id === USER_ROLE_TYPE.CREATOR) {
      //   return <div>Access Denied</div>;
      // }
      if (authUserLoadable.contents === null) {
        return <Redirect to='/auth/login' />
      }
      return <Route {...props} />
    case 'loading':
      return <LoadingScreen zIndex={6000} />
    case 'hasError':
      return <Redirect to='/auth/login' />
  }
}

export function AppRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute exact path={['/', '/presentations']}>
          <MainLayout>
            <MyPresentationsPage />
          </MainLayout>
        </ProtectedRoute>
        <ProtectedRoute exact path='/templates'>
          <MainLayout>
            <TemplatesPage />
          </MainLayout>
        </ProtectedRoute>
        <ProtectedRoute exact path='/store'>
          <MainLayout>
            <AssetStorePage />
          </MainLayout>
        </ProtectedRoute>
        <ProtectedRoute exact path={['/exp/edit', '/pres/edit']}>
          <React.Suspense fallback={<LoadingScreen />}>
            <PresentationEditorPage />
          </React.Suspense>
        </ProtectedRoute>
        <ProtectedRoute exact path='/team'>
          <MainLayout>
            <TeamPage />
          </MainLayout>
        </ProtectedRoute>
        <ProtectedRoute exact path='/showcases'>
          <MainLayout>
            <ManageShowcasesPage />
          </MainLayout>
        </ProtectedRoute>
        <ProtectedRoute exact path='/statistics'>
          <MainLayout>
            <StatisticsPage />
          </MainLayout>
        </ProtectedRoute>
        <ProtectedRoute exact path='/account'>
          <MainLayout>
            <AccountPage />
          </MainLayout>
        </ProtectedRoute>
        <ProtectedRoute exact path='/account/delete'>
          <React.Suspense fallback={<LoadingScreen />}>
            <DeleteAccountPage />
          </React.Suspense>
        </ProtectedRoute>
        <ProtectedRoute exact path='/faq'>
          <MainLayout>
            <FaqPage />
          </MainLayout>
        </ProtectedRoute>
        <Route path='/auth'>
          <React.Suspense fallback={<LoadingScreen />}>
            <AuthenticationPage />
          </React.Suspense>
        </Route>
        <Route path='/verify/:token'>
          <React.Suspense fallback={<LoadingScreen />}>
            <VerifyAccountPage />
          </React.Suspense>
        </Route>
        <Route path='/forgot-password'>
          <React.Suspense fallback={<LoadingScreen />}>
            <ForgotPasswordPage />
          </React.Suspense>
        </Route>
        <Route path='/reset-password/:token'>
          <React.Suspense fallback={<LoadingScreen />}>
            <ResetPasswordPage />
          </React.Suspense>
        </Route>
        <Route path={['/showcase/:uuid', '/showcase']}>
          <React.Suspense fallback={<LoadingScreen />}>
            <ShowcasePage />
          </React.Suspense>
        </Route>
        <Route exact path={['/view/:uuid']}>
          <React.Suspense fallback={<LoadingScreen />}>
            <PresentationViewerPage />
          </React.Suspense>
        </Route>
        <Route exact path={['/embed/:uuid', '/embed/view/:uuid']}>
          <React.Suspense fallback={<LoadingScreen />}>
            <PresentationViewerPage embedded />
          </React.Suspense>
        </Route>

        <Route>
          <div>Page not found</div>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
