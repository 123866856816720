import { Quaternion, Tools } from "@babylonjs/core";
import axios from "axios";

import { MAX_AR_IMG_QUERY_WIDTH } from "../../constants";
import {
  Asset,
  AssetType,
  Experience,
  Marker,
  PlayableAsset,
} from "../../models/exp-entities";
import {
  AssetURLResponse,
  ExperienceListQuery,
  ExperienceRequest,
  GET_AssetURL,
  GET_ExperienceList,
  POST_Experience,
  POST_MarkerUpload,
  POST_PresignedAssetPOST,
} from "../../xr-platform-api";

const generateSessionID = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
};

const getToken = (exp: Experience) => {
  return exp.access_token ?? undefined;
};

/**
 * Handles initialization of experience and creates new entry in backend.
 * @param markerFile The image target image file of experience.
 * @param expName Name of experience.
 * @returns Experience ready for ExperienceSceneManager.
 */
export const initExperienceAsync = async (
  markerFile?: File,
  expName?: string,
  isVertical: boolean = false
) => {
  let req: ExperienceRequest = {
    name: expName ?? "Untitled",
    asset_uuids: [],
    asset_transform_info: [],
    scene_color: "#ffffff",
    marker_floor_to_center_height: isVertical ? 1.5 : 0,
    settings: { is_vertical: isVertical },
  };
  let marker: Marker | null = null;
  if (markerFile) {
    marker = (await POST_MarkerUpload(markerFile, true)).marker;
    // await PUT_MarkerUpdate(marker.uuid, {
    //   floor_to_center_height: isVertical ? 1.5 : 0,
    // });
    req.marker_uuid = marker.uuid;
  }
  const { experience } = await POST_Experience(req, "asset_uuids");
  if (marker && markerFile) {
    // marker.floor_to_center_height = isVertical ? 1.5 : 0;
    marker.url = URL.createObjectURL(markerFile);
    marker.session_id = generateSessionID();
    experience.marker = marker;
  }
  // experience.scene_color = "#ffffff";
  return experience;
};

/**
 * Handles retrieving experience and asset source from backend.
 * @param uuid UUID of experience to load from backend.
 * @param add_props Additional props to include in returned experience.
 * @param experience Experience to load. Use this if you already have experience data handy.
 * @returns Experience ready for ExperienceSceneManager.
 */
export const loadExperienceAsync = async (
  // uuid: string,
  // add_props?: ExperienceAddPropsQuery | ExperienceAddPropsQuery[],
  query: ExperienceListQuery,
  experience?: Experience,
  forViewer?: boolean
) => {
  const exp = experience
    ? experience
    : (await GET_ExperienceList(query)).experiences[0];

  if (forViewer && exp.settings.is_public === false) {
    throw new Error("This presentation is private.");
  }

  if (exp.marker) {
    const res = await GET_AssetURL(
      exp.marker!.uuid,
      true,
      undefined,
      getToken(exp)
    );

    const url = res.url ?? res.backup_url ?? "";
    exp.marker!.url = url;
    exp.marker!.session_id = generateSessionID();
  }

  const GET_SoundAssetURL = async (a: Asset) => {
    const res = await GET_AssetURL(a.uuid, false, {}, getToken(exp));
    const blobRes = await axios.get<Blob>(res.url ?? res.backup_url ?? "", {
      responseType: "blob",
    });
    const objURL = URL.createObjectURL(blobRes.data);
    const response: AssetURLResponse = {
      url: objURL,
      backup_url: objURL,
      error: null,
      status: "",
    };
    return response;
  };
  const getAssetsURL = exp.asset_transform_info.map(a =>
    a.type === "audio"
      ? GET_SoundAssetURL(a)
      : GET_AssetURL(
          a.uuid,
          false,
          {
            width: a.type === "image" ? MAX_AR_IMG_QUERY_WIDTH : undefined,
          },
          getToken(exp)
        )
  );
  const results = await Promise.all(getAssetsURL);
  results.forEach((res, i) => {
    let url = res.url ?? res.backup_url ?? "";
    const asset = exp.asset_transform_info[i];
    asset.url = url;
    asset.session_id = generateSessionID();
    if (asset.strPos === undefined) {
      asset.strPos = asset.position
        ? Object.values(asset.position).map(v => v.toString())
        : ["0", "0", asset.type === "3d" ? "-0.5" : "-0.01"];
    }
    if (asset.strRot === undefined) {
      asset.strRot = [
        asset.rotation?.x.toString() ?? "0",
        asset.rotation?.y.toString() ?? "0",
        asset.rotation?.z.toString() ?? "0",
        asset.rotation?.w.toString() ?? "1",
      ];
    }
    if (asset.strScl === undefined) {
      asset.strScl = asset.scale
        ? Object.values(asset.scale).map(v => v.toString())
        : ["1", "1", "1"];
    }
  });
  return exp;
};

/**
 * Handles upload of asset and association with provided experience. Does not modify provided experience.
 * @param exp Experience to update.
 * @param file Asset file to upload.
 * @param type The asset type accepted by XRPlatform.
 * @returns Shallow copy of updated experience.
 */
export const addExperienceAssetAsync = async (
  exp: Experience,
  file: File,
  type: AssetType,
  replaceWith?: Asset,
  onUploadProgress?: (progess: any) => void
): Promise<Experience> => {
  if (exp.asset_uuids === undefined) {
    throw new Error(
      "Experience is missing property asset_uuids. Cannot add new Asset"
    );
  }

  let { asset } = await POST_PresignedAssetPOST(file, type, onUploadProgress);
  const objURL = URL.createObjectURL(file);
  if (asset.type === "image") {
    const imgPromise = new Promise<{ w: number; h: number }>(
      (resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          resolve({ w: img.width, h: img.height });
        };
        img.onerror = ev => {
          reject(ev.toString());
        };
        img.src = objURL;
      }
    );
    const { w, h } = await imgPromise;
    asset.original_width = w;
    asset.original_height = h;
  } else if (asset.type === "video") {
    const vidPromise = new Promise<{ w: number; h: number }>(
      (resolve, reject) => {
        const vid = document.createElement("video");
        vid.preload = "metadata";
        vid.onloadedmetadata = () => {
          resolve({ w: vid.videoWidth, h: vid.videoHeight });
        };
        vid.onerror = ev => {
          reject(ev.toString());
        };
        vid.src = objURL;
      }
    );
    const { w, h } = await vidPromise;
    asset.original_width = w;
    asset.original_height = h;
    (asset as PlayableAsset).volume = 1;
  } else if (asset.type === "audio") {
    asset.original_width = 0.25;
    asset.original_height = 0.25;
    (asset as PlayableAsset).volume = 1;
  }
  if (replaceWith) {
    asset = { ...replaceWith, ...asset };
  } else {
    const quat = Quaternion.FromEulerAngles(
      exp.settings.is_vertical ? 0 : Tools.ToRadians(90),
      0,
      0
    );
    asset.position = { x: 0, y: 0, z: asset.type === "3d" ? -0.05 : -0.01 };
    asset.rotation = { x: quat.x, y: quat.y, z: quat.z, w: quat.w };
    asset.scale = { x: 1, y: 1, z: 1 };
    asset.strPos = ["0", "0", asset.type === "3d" ? "-0.05" : "-0.01"];
    asset.strRot = [
      quat.x.toString(),
      quat.y.toString(),
      quat.z.toString(),
      quat.w.toString(),
    ];
    asset.strScl = ["1", "1", "1"];
    asset.session_id = generateSessionID();
  }
  asset.name = file.name;
  asset.url = objURL;

  if (replaceWith) {
    const index = exp.asset_transform_info.findIndex(
      a => a.session_id === replaceWith.session_id
    );
    if (index === -1) return exp;

    return {
      ...exp,
      asset_uuids: [
        ...exp.asset_uuids.map(a => (a === replaceWith.uuid ? asset.uuid : a)),
      ],
      asset_transform_info: [
        ...exp.asset_transform_info.slice(0, index),
        asset,
        ...exp.asset_transform_info.slice(index + 1),
      ],
    };
  }

  return {
    ...exp,
    asset_uuids: [...exp.asset_uuids, asset.uuid],
    asset_transform_info: [...exp.asset_transform_info, asset],
  };
};

/**
 * Get copy of asset from provided experience.
 * @param exp The experience to select the asset from.
 * @param sessionID The session identifier of the asset.
 * @returns Shallow copy of asset or null if not found.
 */
export const selectExperienceAsset = (
  exp: Experience,
  sessionID: string
): Asset | null => {
  const asset = exp.asset_transform_info.find(a => a.session_id === sessionID);
  return asset ? { ...asset } : null;
};

/**
 * Apply asset updated to provided experience. Does not modify provided experience.
 * @param exp Experience to update.
 * @param update Updated data to apply to provided experience.
 * @returns Shallow copy of experience with updated asset.
 */
export const updateExperienceAsset = (exp: Experience, update: Asset) => {
  const i = exp.asset_transform_info.findIndex(
    a => a.session_id === update.session_id
  );
  if (i !== -1) {
    return {
      ...exp,
      asset_transform_info: [
        ...exp.asset_transform_info.slice(0, i),
        update,
        ...exp.asset_transform_info.slice(i + 1),
      ],
    };
  }
  return exp;
};

/**
 *
 * @param exp The experience to remove asset from.
 * @param sessionID Identifier of the asset to be deleted.
 * @returns Shallow copy of updated experience;
 */
export const removeExperienceAsset = (
  exp: Experience,
  sessionID: string
): Experience => {
  return {
    ...exp,
    asset_transform_info: exp.asset_transform_info.filter(
      a => a.session_id !== sessionID
    ),
  };
};
