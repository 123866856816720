import React from "react";
import { Box, IconButton } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";

type Props = {
  page: number;
  perPage: number;
  totalCount: number;
  onPageTurn: (pageOffset: number) => void;
};

export const Pagination: React.FC<Props> = ({
  page,
  perPage,
  totalCount,
  onPageTurn,
}) => {
  return (
    <Box
      width={200}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      margin="16px auto"
    >
      <IconButton disabled={page <= 1} onClick={() => onPageTurn(-1)}>
        <Icon path={mdiArrowLeft} size={1} />
      </IconButton>
      <Box>{`${page}/${Math.ceil(totalCount / perPage)}`}</Box>
      <IconButton
        disabled={page >= totalCount / perPage}
        onClick={() => onPageTurn(1)}
      >
        <Icon path={mdiArrowRight} size={1} />
      </IconButton>
    </Box>
  );
};
