import {
  Button,
  CardActions,
  CardMedia,
  IconButton,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { mdiPause, mdiPlay } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

import { BouncyShadowCard } from "../../components/BouncyShadowCard";
import { Asset } from "../../models/exp-entities";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(1, 1, 0.5),
      "& .media": {
        backgroundColor: theme.palette.divider,
        position: "relative",
        width: "100%",
        height: 0,
        //4:3
        paddingTop: "100%",
        marginBottom: theme.spacing(1),
        border: `solid ${theme.palette.divider} ${theme.spacing(0.1)}px`,
        borderRadius: theme.shape.borderRadius,
        "& video": {
          position: "absolute",
          top: 0,
          borderRadius: theme.shape.borderRadius,
        },
        "& #audio-preview": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          borderRadius: theme.shape.borderRadius,
          outline: "none",
          border: "none",
          position: "absolute",
          top: 0,
        },
        "& #model-preview": {
          width: "100%",
          height: "100%",
          borderRadius: theme.shape.borderRadius,
          outline: "none",
          border: "none",
          position: "absolute",
          top: 0,
        },
      },
      "& .meta": {
        display: "flex",
        justifyContent: "space-between",
      },
      "& .tag": {
        margin: theme.spacing(0.5, 1, 0.5, 0),
      },
    },
  })
);

type Props = {
  asset: Asset;
  forStore?: boolean;
  onPurchase?: (asset: Asset) => void;
  onImport?: (asset: Asset) => void;
};

export const AssetCard: React.FC<Props> = ({
  asset,
  forStore,
  onPurchase,
  onImport,
}) => {
  const styles = useStyles();
  const [playbackIcon, setPlaybackIcon] = React.useState(mdiPlay);

  const handleAudioPlayback = (assetID: number) => () => {
    const audio = document.getElementById(
      `audio-${assetID}`
    ) as HTMLAudioElement | null;
    if (audio) {
      if (audio.paused) {
        audio.play();
        setPlaybackIcon(mdiPause);
      } else {
        audio.pause();
        setPlaybackIcon(mdiPlay);
      }
    }
  };

  const handleButton = async () => {
    if (asset.belongs_to_my_teams && onImport) {
      onImport(asset);
    } else if (onPurchase) {
      onPurchase(asset);
    }
  };

  return (
    <BouncyShadowCard className={styles.root}>
      {/* <CardActionArea className="actionArea" onClick={onClick}> */}
      {asset.type === "audio" && (
        <CardMedia className="media" image={asset.thumbnail_asset_url ?? ""}>
          <div id="audio-preview">
            <IconButton
              onClick={handleAudioPlayback(asset.id!)}
              style={{ color: "white" }}
            >
              <Icon path={playbackIcon} size={2} />
            </IconButton>
            <audio id={`audio-${asset.id}`} src={asset.url_512 ?? ""} />
          </div>
        </CardMedia>
      )}
      {(asset.type === "image" || asset.type === "3d") && (
        <CardMedia
          className="media"
          image={
            (asset.preview_image_urls?.length
              ? asset.preview_image_urls[asset.preview_image_urls.length - 1]
              : undefined) ??
            asset.url_512 ??
            asset.thumbnail_asset_url ??
            ""
          }
        />
      )}
      {asset.type === "video" && (
        <div className="media">
          <video
            src={asset.url_512 ?? ""}
            width="100%"
            height="100%"
            controls
            controlsList="nodownload noremoteplayback"
          />
        </div>
      )}
      <Typography noWrap>
        <b>{asset.name ?? "NO NAME"}</b>
      </Typography>
      {/* <div className="meta"> */}
      <Typography variant="caption" display="block">
        {asset.type?.toUpperCase() ?? "UNKNOWN"}
      </Typography>
      {forStore && (
        <Typography variant="subtitle2" display="block" gutterBottom>
          {`${
            asset.belongs_to_my_teams
              ? "OWNED"
              : asset.price
              ? "$" + (asset.price / 100).toFixed(2)
              : "FREE"
          }`}
        </Typography>
      )}

      {/* </div> */}
      <CardActions>
        <Button fullWidth color="primary" size="small" onClick={handleButton}>
          {asset.belongs_to_my_teams || Boolean(asset.price) === false
            ? "Import"
            : "Purchase"}
        </Button>
      </CardActions>
    </BouncyShadowCard>
  );
};
