import { styled } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiMoonWaxingCrescent } from "@mdi/js";

const MoonIcon = styled(props => (
  <Icon {...props} path={mdiMoonWaxingCrescent} size={0.85} />
))(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.text.secondary,
  border: `solid ${theme.palette.primary.main} 1px`,
  borderRadius: "50%",
  boxShadow: theme.shadows[3],
}));

export default MoonIcon;
