import { atom, selector } from 'recoil'

import { User } from '../../../types'
import { logInAPI } from './authAPI'

export const authState = atom<User | null>({
    key: 'authState',
    default: logInAPI(
        undefined
        // 'file_size,file_size_h,max_file_size,max_file_size_h,count_experience_views_h'
    )
        .then(data => {
            if (data.access_token === null) {
                throw new Error('Account not verified')
            }
            return data.user
        })
        .catch(reason => null)
})

export const authUserIDState = selector({
    key: 'authUserIDState',
    get: ({ get }) => get(authState)?.id
})
export const authUserEmailState = selector({
    key: 'authUserEmailState',
    get: ({ get }) => get(authState)?.email
})
export const authUserRoleType = selector({
    key: 'authUserRoleType',
    get: ({ get }) => get(authState)?.role_type_id
})
export const authTeamIDState = selector({
    key: 'authTeamIDState',
    get: ({ get }) => get(authState)?.team_id
})
