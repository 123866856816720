import TagManager from "react-gtm-module";

const reportServerError = (reason: any) => {
  if (process.env.NODE_ENV !== "production") return;
  TagManager.dataLayer({
    dataLayer: {
      event: "server_error",
      error_code: reason.response.status,
      error_text: reason.response.statusText,
      request_url: reason.config.url,
    },
  });
};

export default reportServerError;
