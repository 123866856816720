import useSWR, { SWRConfiguration } from "swr";
import { getCustomerAPI } from "./stripeAPI";
export const useStripeCustomer = (config?: SWRConfiguration<any>) => {
  const { data, error, isValidating, mutate } = useSWR(
    "getCustomer",
    key => getCustomerAPI(),
    config
  );

  return {
    customer: data,
    isLoading: !data && !error,
    error,
    isValidating,
    mutate,
  };
};
