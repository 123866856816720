import React from "react";
import { SvgIcon } from "@material-ui/core";
import type { SvgIconProps } from "@material-ui/core";

const TemplatesIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="483.359"
      viewBox="0 0 512 483.359"
      {...props}
    >
      <path
        id="Templates"
        d="M4011.369,9928.359a13.023,13.023,0,1,1,0-26.047h208.258a13.023,13.023,0,0,1,0,26.047Zm-45.562-55.8a13.02,13.02,0,0,1,0-26.039h299.379a13.02,13.02,0,0,1,0,26.039Zm-58.574-55.7a13.027,13.027,0,0,1,0-26.055h416.531a13.027,13.027,0,0,1,0,26.055Zm-34.719-55.8a13.027,13.027,0,0,1-13.016-13.016V9458.031A13.025,13.025,0,0,1,3872.514,9445h485.969a13.025,13.025,0,0,1,13.016,13.031v290.008a13.027,13.027,0,0,1-13.016,13.016Z"
        transform="translate(-3859.498 -9445)"
      />
    </SvgIcon>
  );
};

export default TemplatesIcon;
