import { styled } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiWeatherSunny } from "@mdi/js";

const SunIcon = styled(props => (
  <Icon {...props} path={mdiWeatherSunny} size={0.85} />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.secondary.main,
  border: `solid ${theme.palette.background.default} 1px`,
  borderRadius: "50%",
  boxShadow: theme.shadows[3],
}));

export default SunIcon;
