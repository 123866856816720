import useSWR, { SWRConfiguration } from 'swr'
import { getTeamAPI, getTeamExperienceCountAPI } from './teamAPI'

import { Team, TeamMember } from '../../types/team'
import { getTeamMembersAPI } from './teamAPI'
import {
  getTeamsApi,
  PaginatedTeamsResponse,
  TeamsQuery
} from 'xr-platform-api'

export const useTeam = (teamID?: number, config?: SWRConfiguration<Team>) => {
  const { data, error, isValidating, mutate } = useSWR(
    teamID ? ['getTeam', teamID] : null,
    (key, teamID) => getTeamAPI(teamID),
    config
  )

  return {
    team: data,
    isLoading: !error && !data,
    error,
    isValidating,
    mutate
  }
}

export const useTeams = (
  query?: TeamsQuery,
  config?: SWRConfiguration<PaginatedTeamsResponse>
) => {
  const { data, error, isValidating, mutate } = useSWR(
    query ? ['queryTeams', JSON.stringify(query)] : null,
    (key, queryString) => getTeamsApi(query),
    config
  )

  return {
    teams: data?.teams ?? [],
    isLoading: !error && !data,
    error,
    isValidating,
    mutate
  }
}

export const useTeamMembers = (
  teamID?: number,
  config?: SWRConfiguration<TeamMember[]>
) => {
  const { data, error, isValidating, mutate } = useSWR(
    teamID ? ['getTeamMembers', teamID] : null,
    (key, teamID) => getTeamMembersAPI(teamID),
    config
  )

  return {
    members: data,
    isLoading: !error && !data,
    error,
    isValidating,
    mutate
  }
}

export const useTeamExperienceCount = (
  teamID?: number,
  exclude_super_users = true,
  exclude_deleted_users = true
) => {
  const { data, error, isValidating, mutate } = useSWR(
    teamID
      ? [
          'GET_TeamExperienceCount',
          teamID,
          exclude_super_users,
          exclude_deleted_users
        ]
      : null,
    (key, teamID) =>
      getTeamExperienceCountAPI(
        teamID,
        exclude_super_users,
        exclude_deleted_users
      ),
    { revalidateOnFocus: false }
  )

  return {
    count_experiences: data?.count_experiences,
    max_count_experiences: data?.max_count_experiences,
    error,
    isValidating,
    mutate,
    isLoadidng: !data && !error
  }
}
