// eslint-disable-next-line
import '../src/helpers/setUpExpLoadingScreen'

import {
  createMuiTheme,
  createStyles,
  CssBaseline,
  Fade,
  makeStyles,
  responsiveFontSizes,
  Theme,
  ThemeProvider as MuiThemeProvider,
  useMediaQuery
} from '@material-ui/core'
import { cyan, deepOrange, lightBlue, pink } from '@material-ui/core/colors'
import { TransitionProps } from '@material-ui/core/transitions'
import { Elements } from '@stripe/react-stripe-js'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { AppRouter } from './pages/AppRouter'
import initGTM from './config/gtm'
import stripeConfig from './config/stripe'
import { darkModeState } from './features/account/user/userPrefStore'

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAcah1tegZcJAFLCKu8MIqwAR15uf852Zo",
//   authDomain: "post-reality-studio-site.firebaseapp.com",
//   projectId: "post-reality-studio-site",
//   storageBucket: "post-reality-studio-site.appspot.com",
//   messagingSenderId: "1025640020851",
//   appId: "1:1025640020851:web:108245ab7e15d6f04b2306",
//   measurementId: "G-NJV832MSZQ",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

if (process.env.NODE_ENV === 'production') {
  initGTM()
}

const useSnackbarStyles = makeStyles(theme =>
  createStyles({
    anchorOriginBottomCenter: { minWidth: 0 },
    anchorOriginBottomRight: {
      minWidth: 0
    }
  })
)

function App() {
  const classes = useSnackbarStyles()
  const darkMode = useRecoilValue(darkModeState)

  let baseTheme = React.useMemo((): Theme => {
    const theme = createMuiTheme({
      palette: {
        type: darkMode ? 'dark' : 'light',
        background: { default: darkMode ? '#303030' : '#f0f0f0' },
        primary: { main: darkMode ? cyan[100] : lightBlue['A700'] },
        secondary: { main: darkMode ? pink['A100'] : deepOrange[500] }
      },
      shape: { borderRadius: 8 },
      props: {
        MuiTextField: {
          variant: 'outlined'
        },

        MuiButton: {
          disableElevation: true,
          variant: 'contained'
        },
        MuiButtonGroup: {
          disableElevation: true,
          variant: 'contained'
        },
        MuiPaper: {
          elevation: 0
        },
        MuiCard: {
          elevation: 0
        },
        MuiTooltip: {
          arrow: true
        }
      }
    })
    return {
      ...theme,
      overrides: {
        MuiContainer: {
          root: {
            paddingBottom: theme.spacing(5)
          }
        }
      }
    }
  }, [darkMode])
  baseTheme = responsiveFontSizes(baseTheme)

  const match = useMediaQuery(baseTheme.breakpoints.up('md'))

  return (
    <MuiThemeProvider theme={baseTheme}>
      <CssBaseline />
      <SnackbarProvider
        dense={!match}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        TransitionComponent={Fade as React.FunctionComponent<TransitionProps>}
        TransitionProps={{ style: { minWidth: 0, minHeight: 64 } }}
        classes={{
          anchorOriginBottomCenter: classes.anchorOriginBottomCenter,
          anchorOriginBottomRight: classes.anchorOriginBottomRight
        }}
      >
        <Elements stripe={stripeConfig}>
          <AppRouter />
        </Elements>
      </SnackbarProvider>
    </MuiThemeProvider>
  )
}

export default App
