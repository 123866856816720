import { Box, CircularProgress, Typography } from "@material-ui/core";
import React from "react";

export function LoadingScreen(props: {
  width?: number | string;
  height?: number | string;
  position?: string;
  zIndex?: number;
}) {
  const { width, height, position, zIndex } = props;
  return (
    <Box
      width={width ?? "100vw"}
      height={height ?? "100vh"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      position={position}
      zIndex={zIndex}
    >
      <CircularProgress size={48} thickness={3} style={{ margin: 16 }} />
      <Typography variant="h4">Loading</Typography>
    </Box>
  );
}
