import { Container } from '@material-ui/core';
import React from 'react';

import { AssetStore } from './AssetStore';

const AssetStorePage: React.FC = () => {
  const [open, setOpen] = React.useState(true);
  return (
    <Container id="root" maxWidth="xl" style={{ overflowX: 'hidden' }}>
      <AssetStore
        open={open}
        container={document.getElementById('root')}
        onClose={() => setOpen(!open)}
      />
    </Container>
  );
};

export default AssetStorePage;
