import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

export function ConfirmationDialog(props: {
  open: boolean;
  title?: React.ReactNode;
  message: React.ReactNode;
  yesColor?: "primary" | "secondary";
  onNoClick: () => void;
  onYesClick: () => void;
  isLoading?: boolean;
}) {
  const { open, title, message, yesColor, onNoClick, onYesClick, isLoading } =
    props;

  const handleNo = () => {
    if (onNoClick) onNoClick();
  };

  const handleYes = () => {
    if (onYesClick) onYesClick();
  };

  return (
    <Dialog
      open={open}
      onClose={isLoading ? undefined : onNoClick}
      // aria-labelledby="logout-confirmation"
      // aria-describedby="logout-confirmation-description"
    >
      {Boolean(title) && (
        <DialogTitle id="confirmation-title">{title}</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText id="confirmation-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          // fullWidth
          onClick={handleNo}
          disabled={isLoading}
        >
          No
        </Button>
        <Button
          color={yesColor ?? "primary"}
          // fullWidth
          onClick={handleYes}
          disabled={isLoading}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
