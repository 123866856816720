import { Button, Link, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import { useRecoilState } from "recoil";

import { cookieConsentState } from "../features/account/user/userPrefStore";

export function CookieConsent() {
  const [consented, setConsent] = useRecoilState(cookieConsentState);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCloseSnackbar = React.useCallback(
    (key: string | number, accepted: boolean) => {
      if (accepted) setConsent(accepted);
      closeSnackbar(key);
    },
    [setConsent, closeSnackbar]
  );

  React.useEffect(() => {
    // let key: string | number = '';
    if (!consented) {
      enqueueSnackbar(
        <Typography style={{ maxWidth: 630 }}>
          This site uses cookies to deliver our services. By using our site, you
          acknowledge that you have read and understand our{" "}
          <Link href="https://postreality.io/terms" target="_blank">
            Terms of Service and Cookie Policy
          </Link>
          . Your use of Post Reality's Products and Services, is subject to
          these policies and terms.
        </Typography>,
        {
          persist: true,
          action: key => (
            <Button
              color="secondary"
              onClick={() => {
                handleCloseSnackbar(key, true);
              }}
            >
              Accept
            </Button>
          ),
          // content: (id, msg) => (
          //   <Box component={Paper} width={200}>
          //     {msg}
          //   </Box>
          // ),
        }
      );
    }
    // return () => {
    //   if (!consented) {
    //     handleCloseSnackbar(key, false);
    //   }
    // };
  }, [consented, enqueueSnackbar, handleCloseSnackbar]);

  return null;
}
