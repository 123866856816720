import axios from 'axios'

import reportServerError from '../../helpers/reportServerError'
import { ServerResponse, Team, TeamMember } from '../../types'
import { API_ROOT } from '../../config/BackendApi';

export const getTeamAPI = async (id: number) => {
  try {
    const response = await axios.get<Team>(`${API_ROOT}/api/v1/team/${id}`)
    return response.data
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason)
      throw reason.response.data.error
    }
    throw reason
  }
}

export const getTeamMembersAPI = async (id: number) => {
  try {
    const response = await axios.get<
      ServerResponse & {
        users: TeamMember[]
      }
    >(`${API_ROOT}/api/v2/team/${id}/admin-panel/users`, {
      params: { exclude_supers: 1 }
    })
    return response.data.users
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason)
      throw reason.response.data.error
    }
    throw reason
  }
}

export const getTeamExperienceCountAPI = async (
  teamID: number,
  exclude_super_users = true,
  exclude_deleted_users = true
) => {
  try {
    const response = await axios.get<
      ServerResponse & {
        count_experiences: number
        max_count_experiences: number | null
      }
    >(`${API_ROOT}/api/v1/team/${teamID}/count-experiences`, {
      params: { exclude_super_users, exclude_deleted_users }
    })
    return response.data
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason)
      throw reason.response.data.error
    }
    throw reason
  }
}

export const updateTeamLogoAPI = async (
    id: number,
    file: File,
    access_token?: string
) => {
    try {
        const data = new FormData();
        data.append("logo_image", file, file.name);
        const response = await axios.put<
            ServerResponse & { accessToken: string; team: Team }
        >(`${API_ROOT}/api/v1/team/${id}`, data, {
            headers: {
                Authorization: access_token
                    ? 'Bearer ' + access_token
                    : axios.defaults.headers.common['Authorization'],
                'Content-Type': 'application/json'
            }
        })
        return response.data
    } catch (reason) {
        if (reason.response) {
            reportServerError(reason);
            throw reason.response.data.error;
        }
        throw reason;
    }
};