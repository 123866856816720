import {
  Avatar,
  CardActionArea,
  createStyles,
  Link,
  makeStyles
} from '@material-ui/core'
import React from 'react'

import appIcon from '../resources/img/app_icon.png'
import { BouncyShadowCard } from './BouncyShadowCard'

interface StyleProps {
  width?: number
  height?: number
  elevation?: number
  interactive?: boolean
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: (props: StyleProps) =>
        props.width ? theme.spacing(props.width) : undefined,
      height: (props: StyleProps) =>
        props.height ? theme.spacing(props.height) : undefined
    }
  })
)

export function AppIconAvatar(props: StyleProps) {
  const { interactive, ...styleProps } = props
  const classes = useStyles(styleProps)
  return (
    <BouncyShadowCard>
      <CardActionArea
        /* disabled={!interactive} */ component={Link}
        href='https://postreality.io'
        target='_blank'
      >
        <Avatar className={classes.root} src={appIcon} variant='rounded' />
      </CardActionArea>
    </BouncyShadowCard>
  )
}
