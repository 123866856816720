import axios from 'axios'
import { isBackendError } from 'common/helpers'
import { XRPServerResponse } from 'common/types/xrp'
import { User } from 'types'

import { DOMAIN } from '../../../constants'
import reportServerError from '../../../helpers/reportServerError'
import { API_ROOT } from '../../../config/BackendApi'

export type LoginResponse = {
  access_token: string | null
  user: User
} & XRPServerResponse

/**
 * User login endpoint. Omit credentials to verify login with session cookie instead.
 * @param credentials Username and Password
 */
export const logInAPI = (
  credentials?: {
    email_or_username: string
    password: string
    remember?: boolean
  },
  add_props?: string
) =>
  axios({
    url: credentials ? `${API_ROOT}/api/v2/login` : `${API_ROOT}/api/v2/verify_login`,
    method: credentials ? 'POST' : 'GET',
    data: credentials && {
      ...credentials,
      domain: DOMAIN,
      remember_duration: 7 /* days */
    },
    headers: { 'Content-Type': 'application/json' },
    params: { add_props }
  })
    .then(res => {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + res.data.access_token
      return res.data as LoginResponse
    })
    .catch(reason => {
      if (isBackendError(reason)) {
        throw reason.response.data.error
      }
      throw reason
    })

export const logOutAPI = async (): Promise<void> => {
  try {
    await axios.get(`${API_ROOT}/api/v1/logout`)
    return
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason)
      throw reason.response.data.error
    }
    throw reason
  }
}

/**
 * Send email for user verification.
 * @param id The user id.
 */
export const sendVerificationEmailAPI = async (id: number) => {
  try {
    const response = await axios.post<XRPServerResponse>(
      `${API_ROOT}/api/v2/user/${id}/send_verify_email`
      // null,
      // { headers: { "Content-Type": "application/json" } }
    )
    return response.data
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason)
      throw reason.response.data.error
    }
    throw reason
  }
}

/**
 * Verifys a user's account.
 * @param token The verification token the user should have received via email.
 */
export const verifyAccountAPI = async (token: string) => {
  try {
    const response = await axios.put<XRPServerResponse>(
      `${API_ROOT}/api/v1/verify-email/${token}`
    )
    return response.data
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason)
      throw reason.response.data.error
    }
    throw reason
  }
}

/**
 * Sends a password reset email to provided email.
 * Will fail if email is not in the DB.
 * @param email User's email.
 */
export const sendForgotPasswordEmailAPI = async (email: string) => {
  try {
    const response = await axios.post<XRPServerResponse>(
      `${API_ROOT}/api/v2/forgot-password/send-token`,
      {
        source_email: 'no-reply@postreality.io',
        target_email: email,
        domain: DOMAIN,
        template_name_suffix: 'post_reality'
      }
    )
    return response.data
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason)
      throw reason.response.data.error
    }
    throw reason
  }
}

export const getForgotPasswordUserAPI = async (token: string) => {
  try {
    const response = await axios.post<
      XRPServerResponse & { accessToken: string; user: User }
    >(`${API_ROOT}/api/v1/forgot-password/lookup-user`, {
      token
    })
    return response.data
  } catch (reason) {
    if (reason.response) {
      reportServerError(reason)
      throw reason.response.data.error
    }
    throw reason
  }
}
