import { atom, DefaultValue, selector } from "recoil";
import { getCookie, setCookie } from "../../../helpers/cookies";

// Whether the use has consented to cookies.
const consented: boolean = JSON.parse(
  getCookie("pr-cookie-consent") ?? "false"
);
// User's prefered theme mode.
const enableDarkMode: boolean = JSON.parse(
  window.localStorage.getItem("dark-mode-enabled") ?? "false"
);
// User's default language. Consider making this a cookie item.
const language = window.localStorage.getItem("language") ?? "en";
// User's sibebar collapsed state.
const compact: boolean = JSON.parse(
  window.localStorage.getItem("compact-mode-enabled") ?? "false"
);
// Whether to show tutorial everytime.
const tutorial: boolean = JSON.parse(
  window.localStorage.getItem("tutorial-enabled") ?? "true"
);

export const userPrefState = atom({
  key: "userPrefState",
  default: { enableDarkMode, language, compact, consented, tutorial },
});

export const darkModeState = selector<boolean>({
  key: "darkModeState",
  get: ({ get }) => get(userPrefState).enableDarkMode,
  set: ({ set }, newValue) => {
    // Sync setting to local storage.
    window.localStorage.setItem("dark-mode-enabled", JSON.stringify(newValue));
    set(userPrefState, prev => {
      if (newValue instanceof DefaultValue) return newValue;
      return { ...prev, enableDarkMode: newValue };
    });
  },
});

export const compactModeState = selector<boolean>({
  key: "compactModeState",
  get: ({ get }) => get(userPrefState).compact,
  set: ({ set }, newValue) => {
    // Sync setting to local storage.
    window.localStorage.setItem(
      "compact-mode-enabled",
      JSON.stringify(newValue)
    );
    set(userPrefState, prev => {
      if (newValue instanceof DefaultValue) return newValue;
      return { ...prev, compact: newValue };
    });
  },
});

export const tutorialModeState = selector<boolean>({
  key: "tutorialModeState",
  get: ({ get }) => get(userPrefState).tutorial,
  set: ({ set }, newValue) => {
    // Sync setting to local storage.
    window.localStorage.setItem("tutorial-enabled", JSON.stringify(newValue));
    set(userPrefState, prev => {
      if (newValue instanceof DefaultValue) return newValue;
      return { ...prev, tutorial: newValue };
    });
  },
});

export const cookieConsentState = selector<boolean>({
  key: "cookieConsentState",
  get: ({ get }) => get(userPrefState).consented,
  set: ({ set }, newValue) => {
    // Sync with cookies
    setCookie("pr-cookie-consent", JSON.stringify(newValue), 10 * 365);
    // set(userPrefState, );
    set(userPrefState, prev => {
      if (newValue instanceof DefaultValue) return newValue;
      return { ...prev, consented: newValue };
    });
  },
});
